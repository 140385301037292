<template>
  <div class="humanities-Page">
    <main-nav />

    <div class="humanities-main-panel">
      <div>
        <div class="top-section">
          <div class="description">
            怀化，别称“鹤城”，古称“鹤州”、“五溪”。湖南省地级市，全国性综合交通枢纽城市，武陵山经济协作区中心城市和节点城市。
            自古以来就有“黔滇门户”、“全楚咽喉”之称，是中国中东部地区通往大西南的“桥头堡”。
            <div class="btn-wrapper">
              <div class="btn-item" @click.stop="anchorPoint('history')">
                怀化历史
              </div>
              <div class="btn-item" @click.stop="anchorPoint('localSpecialty')">
                怀化特产
              </div>
              <div class="btn-item" @click.stop="anchorPoint('advantage')">
                怀化优点
              </div>
            </div>
          </div>
        </div>
        <div class="info-wrapper">
          <div class="info-item" @click.stop="pageJump(0)">
            <div>
              <img src="../assets/humanities/xzqh.png" alt="行政区划" />
            </div>
            <div>
              <p class="title" style="color: #288ce0">行政区划</p>
              <p>
                现辖12个县（市、区）和一个管委会，包括鹤城区、中方县、洪<a
                  href="#"
                  >[更多]</a
                >
              </p>
            </div>
          </div>
          <div class="info-item" @click.stop="pageJump(1)">
            <div>
              <img src="../assets/humanities/zrdl.png" alt="自然地理" />
            </div>
            <div>
              <p class="title" style="color: #27bc0a">自然地理</p>
              <p>
                从地理位置上看，怀化南接广西（桂林、柳州）,
                西连贵州（铜仁、黔东南）与湖南的邵阳、娄底接壤<a href="#"
                  >[更多]</a
                >
              </p>
            </div>
          </div>
          <div class="info-item" @click.stop="pageJump(2)">
            <div>
              <img src="../assets/humanities/hjzy.png" alt="环境资源" />
            </div>
            <div>
              <p class="title" style="color: #fc5f45">环境资源</p>
              <p>
                怀化物产资源丰富，素有“广木之乡”“水果之乡”、“药材之乡”的美誉。全市有活立木蓄积量6690万<a
                  href="#"
                  >[更多]</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="humanities-history" id="history">
      <p class="sectionTitle retouch">怀化历史</p>
      <div>
        <div class="history-wrapper">
          <div class="desc-box">生态福地</div>
          <img src="../assets/humanities/h1.png" alt="" />
        </div>
        <div class="history-wrapper">
          <div class="desc-box">古迹繁多</div>
          <img src="../assets/humanities/h2.png" alt="" />
        </div>
        <div class="history-wrapper">
          <div class="desc-box">建筑优美</div>
          <img src="../assets/humanities/h3.png" alt="" />
        </div>
        <div class="history-wrapper">
          <div class="desc-box">民俗文雅</div>
          <img src="../assets/humanities/h4.png" alt="" />
        </div>
        <div class="history-wrapper" id="title-wrapper">
          <p class="title">文化摇篮/绿色生态</p>
          <p class="subTitle">
            湖湘文化与侗族文化一直在历史的变迁不断交融，民俗多彩多样，文化底蕴极其繁盛。不仅如此，怀化的生态资源十分优越,长寿现象突出,现有百岁以上老人近220人,处全省第二位。麻阳县有百岁以上老人46人，是“中国十大长寿之乡"。
          </p>
        </div>
        <div class="history-wrapper hei-auto">
          <div class="txtarea" style="text-align: right">
            怀化山水延绵,巍巍雪峰山脉盘亘东南，雄伟武陵山脉纵贯西北，雪峰山区每立方厘米负氧离子达8万个以上。森林覆盖率达70.83%，是地球同纬度生态最良好
            的区域之一、中国九大生态良好区域之一、湖南省首个国家级生态示范市,有原始次森林30多处,国家级、省级自然保护区、风景名胜区、地质公园和森林公园27个，
            国家3A级以上景区34个,被誉为“-座会呼吸的城市”。
          </div>
        </div>
        <div class="history-wrapper hei-auto">
          <div class="txtarea" style="text-align: left">
            怀化历史人文璀璨厚重,中华炎帝始祖在这里点燃了人类稻作文明
            的火种,境内发掘的高庙遗址,把中华文明向前推进了2000年;这里是
            成语“学富五车、书通二酉”的出典地;屈原“涉江”于此，留下了干
            古传诵的《离骚》; 大唐龙兴讲寺，是现存世界最古老的书院;兴起于
            明清时期的洪江古商城，被誉为“中国第- -古商城”; 这里是向警予、
            粟裕、滕代远等老-辈无产阶级革命家的故乡;这里是红军长征北，上的
            转兵地，是中华民族抗战胜利的受降地,是世界杂交水稻的发源地......
          </div>
        </div>
      </div>
    </div>

    <div class="humanities-localSpecialty" id="localSpecialty">
      <p class="sectionTitle retouch">怀化特产</p>
      <div class="image-wrapper">
        <div class="image-item">
          <div><img src="../assets/humanities/tc1.png" alt="" v-img /></div>
          <div>
            <p class="title">中国地标产品(水果)</p>
            <p class="content">
              黔阳大红甜橙、黔阳冰糖橙、黔阳脐橙、麻阳柑橘、桐木湘珍珠葡萄、靖州杨梅、大崇金秋梨
            </p>
          </div>
        </div>
        <div class="image-item">
          <div><img src="../assets/humanities/tc2.png" alt="" v-img /></div>
          <div>
            <p class="title">中国地标产品(水果)</p>
            <p class="content">
              黔阳大红甜橙、黔阳冰糖橙、黔阳脐橙、麻阳柑橘、桐木湘珍珠葡萄、靖州杨梅、大崇金秋梨
            </p>
          </div>
        </div>
        <div class="image-item">
          <div><img src="../assets/humanities/tc3.png" alt="" v-img /></div>
          <div>
            <p class="title">沅陵碣滩茶园</p>
            <p class="content">黔阳大红袍</p>
          </div>
        </div>
        <div class="image-item">
          <div><img src="../assets/humanities/tc4.png" alt="" v-img /></div>
          <div>
            <p class="title">中国地标产品(水果)</p>
            <p class="content">
              黔阳大红甜橙、黔阳冰糖橙、黔阳脐橙、麻阳柑橘、桐木湘珍珠葡萄、靖州杨梅、大崇金秋梨
            </p>
          </div>
        </div>
        <div class="image-item">
          <div><img src="../assets/humanities/tc5.png" alt="" v-img /></div>
          <div>
            <p class="title">中国地标产品(药材)</p>
            <p class="content">
              新晃龙脑樟、靖州茯苓、辰溪金银花、通道铁皮石斛
            </p>
          </div>
        </div>
        <div class="image-item">
          <div><img src="../assets/humanities/tc6.png" alt="" v-img /></div>
          <div>
            <p class="title">中国地标产品(畜牧)</p>
            <p class="content">
              新晃黄牛肉、芷江鸭、芷江绿壳鸡蛋、麻阳白鹅、五强溪鱼、雪峰山乌骨鸡、大合坪黑猪、溆浦鹅
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="humanities-advantage" id="advantage">
      <p class="sectionTitle retouch">怀化优点</p>
      <div class="flex-box">
        <div class="left-imgBox">
          <img src="../assets/humanities/2.jpg" alt="" />
        </div>
        <div class="right-infoBox">
          <p class="title">国家十三五规划建议明确</p>
          <p class="subTitle">资本新动向一一追逐新兴产业</p>
          <div class="content">
            围绕建设“绿色怀化”、“幸 福怀化”的目标,将养老服务业列入健康服务业
            发展的重要内容,依托怀化区位交通、生态文化旅游、长寿品牌优势,力争通过5年
            时间,把我市建设成为全国知名的健康养老基地,重点扶持老年人生活照料、医疗
            护理、健康养生、生态文化旅游等服务业发展。
          </div>
          <div class="content">
            我市坚持“亲商护商、合作共赢”的理念,积极完善和落实各项鼓励外来投资
            的优惠政策，全面推行政务公开、行政问责、环境评议制度，全面实施行政审批
            “三清单一目录"和“两集中两到位”改革,努力为广大客商来怀投资兴业营造-
            流的经商环境。
          </div>
          <p class="title">水陆空三位一体，大西湘的交通枢纽</p>
          <p class="subTitle">铁路、公路、航空“三位一体”的交通格局</p>
          <div class="content">
            通道境内209国道、 241省道、枝柳铁路、包 茂高速公路贯穿全境，周
            边接邻广西桂林国际机 场、怀化芷江机场、贵广 高铁、厦蓉高速，形成了
            铁路、公路、航空“三位 一体"的交通格局。
          </div>
          <div class="content">
            通道是两广向湖南的承接 地、过渡带,位处大湘西生态文化
            旅游圈的最南端,紧邻桂林国际 旅游胜地，为张家界、桂林两大
            国家旅游综合改革试验区的叠合 辐射区,是“张桂国际旅游走
            廊”的重要门户,也是对接珠三角 的重要窗口。
          </div>
        </div>
      </div>
    </div>

    <back-to-top
      :custom-style="myBackToTopStyle"
      :visibility-height="300"
      :back-position="0"
      transition-name="fade"
    />

    <Footer />
  </div>
</template>

<script>
import MainNav from "@/components/MainNav/index";
import Footer from "@/components/Footer.vue";

export default {
  name: "Humanities",
  data() {
    return {
      myBackToTopStyle: {
        right: "100px",
        bottom: "100px",
        width: "6rem",
        height: "6rem",
        "border-radius": "50%",
        "line-height": "45px", // 请保持与高度一致以垂直居中
      },
    };
  },
  components: {
    MainNav,
    Footer,
  },
  methods: {
    anchorPoint(name) {
      document.getElementById(name).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    pageJump(index) {
      this.$router.push({
        name: "HuaiHuaInfo",
        query: { index: index },
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 翻页插件
.manual-wrap {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: opacity ease 0.5s;
  transition: opacity ease 0.5s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.manual-wrap.active {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

@media screen and (min-width: 768px) {
  //pc
  .humanities-main-panel {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url("../assets/humanities/Banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
    & > div {
      max-width: 1200px;
      margin: 0 auto;
      .top-section {
        margin: 0;
        height: 328px;
        padding: 45px;
        background-color: rgba(0, 0, 0, 0.5);
        .description {
          color: #fff;
          text-align: left;
          // text-indent: 2em;
          font-size: 23px;
          line-height: 50px;
          margin-bottom: 50px;
        }
        .btn-wrapper {
          display: flex;
          padding: 2%;
          width: 70%;
          margin: auto;
          justify-content: space-between;
          .btn-item {
            cursor: pointer;
            display: inline-block;
            width: 200px;
            height: 60px;
            line-height: 60px;
            color: #cccccc;
            text-align: center;
            font-size: 19px;
            border: 1px solid #808180;
            border-radius: 5px;
            margin-right: 40px;
            &:hover {
              background: #fff;
              color: black;
            }
          }
        }
      }
      .info-wrapper {
        background: #fff;
        padding: 25px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        .info-item {
          display: flex;
          cursor: pointer;
          &:hover img {
            transform: rotateY(360deg);
            -webkit-transform: rotateY(360deg);
            -moz-transform: rotateY(360deg);
            -o-transform: rotateY(360deg);
            -ms-transform: rotateY(360deg);
          }
          & > div:nth-of-type(1) {
            width: 30%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: auto;
              height: auto;
              display: inline-block;
              display: block;
              transition: all 2s ease-in-out;
              -webkit-transition: all 2s ease-in-out;
              -moz-transition: all 2s ease-in-out;
              -o-transition: all 2s ease-in-out;
            }
          }
          & > div:nth-of-type(2) {
            width: 70%;
            p {
              color: #666;
              font-size: 14px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              padding: 0 27px;
              a {
                display: inline-block;
                margin-left: 8px;
                color: #437fd9;
              }
            }
            .title {
              display: block;
              text-align: left;
              margin-bottom: 12px;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 768px) {
  //手机
  .humanities-main-panel {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url("../assets/humanities/Banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
    & > div {
      max-width: 372px;
      margin: 0 auto;
      .top-section {
        margin: 0;
        height: 328px;
        padding: 45px;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 200px;
        .description {
          color: #fff;
          text-align: left;
          // text-indent: 2em;
          font-size: 14px;
          line-height: 35px;
          margin-bottom: 50px;
        }
        .btn-wrapper {
          display: flex;
          padding: 2%;
          width: 112%;
          margin: auto;
          justify-content: space-between;
          .btn-item {
            cursor: pointer;
            display: inline-block;
            width: 200px;
            height: 60px;
            line-height: 60px;
            color: #cccccc;
            text-align: center;
            font-size: 14px;
            border: 1px solid #808180;
            border-radius: 5px;
            margin-right: 40px;
            &:hover {
              background: #fff;
              color: black;
            }
          }
        }
      }
      .info-wrapper {
        background: #fff;
        padding: 25px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 167px;
        .info-item {
          display: flex;
          cursor: pointer;
          &:hover img {
            transform: rotateY(360deg);
            -webkit-transform: rotateY(360deg);
            -moz-transform: rotateY(360deg);
            -o-transform: rotateY(360deg);
            -ms-transform: rotateY(360deg);
          }
          & > div:nth-of-type(1) {
            width: 30%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: auto;
              height: auto;
              display: inline-block;
              display: block;
              transition: all 2s ease-in-out;
              -webkit-transition: all 2s ease-in-out;
              -moz-transition: all 2s ease-in-out;
              -o-transition: all 2s ease-in-out;
            }
          }
          & > div:nth-of-type(2) {
            width: 70%;
            p {
              color: #666;
              font-size: 14px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              padding: 0 27px;
              a {
                display: inline-block;
                margin-left: 8px;
                color: #437fd9;
              }
            }
            .title {
              display: block;
              text-align: left;
              margin-bottom: 12px;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  //pc
  .humanities-history {
    & > div {
      max-width: 1200px;
      margin: 20px auto;
      display: flex;
      flex-wrap: wrap;
      #title-wrapper {
        width: 100%;
        height: auto;
      }
      .hei-auto {
        height: auto !important;
        margin-bottom: 40px;
      }
      .history-wrapper {
        width: 50%;
        height: 350px;
        position: relative;
        .title {
          color: black;
          font-size: 24px;
          padding: 20px;
          font-weight: 800;
          font-family: cursive;
          text-align: center;
        }
        .subTitle {
          color: black;
          width: 70%;
          margin: auto;
          font-family: emoji;
          text-align: center;
        }
        .desc-box {
          position: absolute;
          top: 20px;
          right: 30px;
          color: #fff;
          padding: 15px;
          min-width: 200px;
          min-height: 60px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.4);
        }
        .txtarea {
          padding: 30px 15px 15px 15px;
          text-align: center;
          font-size: 14px;
          height: auto;
          word-break: break-all;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .humanities-advantage {
  .flex-box {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 10px auto 50px auto;
    .left-imgBox {
      width: 45%;
      height: 500px;
      border-radius: 3px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .right-infoBox {
      width: 55%;
      box-sizing: border-box;
      padding: 15px 30px;
      .title {
        font-size: 24px;
        font-family: system-ui;
        margin-top: 25px;
      }
      .subTitle {
        font-family: emoji;
      }
      .content {
        font-family: emoji;
        line-height: 20px;
        margin: 10px 0px;
      }
    }
  }
}
}

@media screen and (min-width: 200px) and (max-width: 768px) {
  //手机
  .humanities-history {
    & > div {
      max-width: 1200px;
      margin: 20px auto;
      display: flex;
      flex-wrap: wrap;
      #title-wrapper {
        width: 100%;
        height: auto;
      }
      .hei-auto {
        height: auto !important;
        margin-bottom: 40px;
      }
      .history-wrapper {
        width: 50%;
        height: 200px;
        position: relative;
        .title {
          color: black;
          font-size: 24px;
          padding: 20px;
          font-weight: 800;
          font-family: cursive;
          text-align: center;
        }
        .subTitle {
          color: black;
          width: 70%;
          margin: auto;
          font-family: emoji;
          text-align: center;
        }
        .desc-box {
          position: absolute;
          top: 20px;
          right: 30px;
          color: #fff;
          padding: 15px;
          min-height: 60px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.4);
        }
        .txtarea {
          padding: 30px 15px 15px 15px;
          text-align: center;
          font-size: 14px;
          height: auto;
          word-break: break-all;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.humanities-localSpecialty {
  position: relative;
  .localSpecialty-title {
    text-align: left;
    max-width: 1200px;
    margin: 8px auto;
    font-size: 24px;
    font-family: fantasy;
  }
  .image-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .image-item {
      width: 30%;
      height: auto;
      margin-bottom: 50px;
      & > div:nth-of-type(1) {
        width: 100%;
        height: 225px;
        border-radius: 3px;
        overflow: hidden;
        &:hover img {
          transform: scale(1.3);
          transition: all 1s ease 0s;
          -webkit-transform: scale(1.3);
          -webkit-transform: all 1s ease 0s;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      & > div:nth-of-type(2) {
        .title {
          color: black;
          font-size: 18px;
          font-weight: 700;
          font-family: serif;
          margin: 5px;
        }
        .content {
          font-family: serif;
          font-size: 15px;
          margin: 2px;
        }
      }
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .humanities-advantage {
    .flex-box {
      display: flex;
      flex-wrap: wrap;
      max-width: 1200px;
      margin: 10px auto 50px auto;
      .left-imgBox {
        width: 100%;
        height: 250px;
        border-radius: 3px;
        overflow: hidden;
        img {
          width: 100%;
          height: 250px;
        }
      }
      .right-infoBox {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 30px;
        .title {
          font-size: 24px;
          font-family: system-ui;
          margin-top: 25px;
        }
        .subTitle {
          font-family: emoji;
        }
        .content {
          font-family: emoji;
          line-height: 20px;
          margin: 10px 0px;
        }
      }
    }
  }

  .humanities-localSpecialty {
    position: relative;
    .localSpecialty-title {
      text-align: left;
      max-width: 1200px;
      margin: 8px auto;
      font-size: 24px;
      font-family: fantasy;
    }
    .image-wrapper {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .image-item {
        width: 30%;
        height: auto;
        margin-bottom: 50px;
        & > div:nth-of-type(1) {
          width: 100%;
          height: 130px;
          border-radius: 3px;
          overflow: hidden;
          &:hover img {
            transform: scale(1.3);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1.3);
            -webkit-transform: all 1s ease 0s;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        & > div:nth-of-type(2) {
          .title {
            color: black;
            font-size: 18px;
            font-weight: 700;
            font-family: serif;
            margin: 5px;
          }
          .content {
            font-family: serif;
            font-size: 15px;
            margin: 2px;
          }
        }
      }
    }
  }
}


//人文栏目
.home-overview {
  margin-bottom: 50px;
  .flex-overview {
    display: flex;
    flex-wrap: wrap;
    height: 500px;
    margin: 0 auto;
    #info {
      width: 31%;
      background: #9153533b;
      padding: 30px 15px;
      & p:nth-of-type(1) {
        font-size: 24px;
        text-align: center;
      }
      & p:nth-of-type(2) {
        font-size: 19px;
        text-align: center;
      }
      & div:nth-of-type(1) {
        height: 80px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        padding-right: 5px;
        box-sizing: border-box;
      }
      & div:nth-of-type(2) {
        width: 50%;
        height: 35px;
        padding: 10px 5px;
        border: #fff 1px solid;
        border-radius: 3px;
        margin: auto;
        text-align: center;
      }
    }
    #info ~ div {
      width: 23%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      & > div {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          p,
          span {
            width: 100%;
            text-align: center;
          }
          p {
            font-size: 18px;
          }
          span {
            display: inline-block;
            font-size: 14px;
          }
        }
      }
      img {
        width: 70%;
        display: inline-block;
        margin: auto;
      }
    }
  }
  .needToKnow {
    height: auto;
    margin: 10px auto 50px auto;
    img {
      width: 100%;
    }
  }
}
</style>
